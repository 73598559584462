import type { VNode, VNodeDirective } from 'vue'
import { getNumCharacters, queryInputElementInside } from '~/helpers'

export default defineNuxtPlugin({
  name: 'digitsOnly',
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.vueApp.directive('digitsonly', {
      beforeMount(el, binding, vnode) {
        const input = queryInputElementInside(el)

        input.addEventListener('input', (e) => {
          if (e && e.isTrusted) handler(e, vnode, input, binding)
        })
      },
      unmounted(el, binding, vnode) {
        const input = queryInputElementInside(el)

        input.removeEventListener('input', (e) => {
          if (e && e.isTrusted) handler(e, vnode, input, binding)
        })
      },
    })

    function handler(
      e: Event,
      vnode: VNode,
      input: HTMLInputElement,
      binding?: VNodeDirective,
    ) {
      if (input.selectionStart && input.selectionStart < 3) {
        e.preventDefault()
      }

      input.value = getNumCharacters(
        (e.target as HTMLInputElement).value,
        binding,
      )
      queryInputElementInside(vnode.el)?.dispatchEvent(new CustomEvent('input'))
    }
  },
})
